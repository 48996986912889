import axios from "axios";
import { authHeader } from './helpers';
// export default axios.create({
//   baseURL: "https://stretchfilmroll.com:3002/api",
//   headers: {
//     "Access-Control-Allow-Origin"  : "*",
//     "Content-type"  : "application/json",
//     'Authorization' : authHeader() 
//   }
// });
//this was responce handler for 401 error to logout 


export default axios.create({
  
  baseURL: `${process.env.VUE_APP_BACKEND_URL}/api`,
  headers: {
    "Access-Control-Allow-Origin"  : "*",
    "Content-type"  : "application/json",
    'Authorization' : authHeader() 
  }
});
