// import { inject } from 'vue'
var cryoptojs = require("crypto-js");

class SessionService{
    getUser = () =>{
        try{
            const encryptUserData = localStorage.getItem('user')
            if(encryptUserData){
                const decryptedUserData = cryoptojs.AES.decrypt(encryptUserData, "vx").toString(cryoptojs.enc.Utf8)
                return JSON.parse(decryptedUserData)
            }else{
                return undefined
            }
        }catch( error ){
            if(error){
                localStorage.removeItem('user');
                localStorage.clear();
                window.location = "/login";
            }
        }
        
        
    }
    setUser = (data) =>{
        const encryptedText = cryoptojs.AES.encrypt(JSON.stringify(data), "vx").toString()
		localStorage.setItem('user', encryptedText);
    }
    setDisconnect = (connection) => {
        localStorage.setItem('connection', connection);
    }
    getconnection = () => {
        const connection =  localStorage.getItem('connection');
        if(connection){ 
            return connection
        }else{
            return undefined
        }

    }
} 
export default new SessionService();