<template>
	<HeaderComponent :key="this.refreshHeaderKey" />
	<div class="p-0">
		<div class="main-con d-flex">
			<SideBar />
			<SideBet @changeKeyHeader="updateHeader()"/>
			<div class="tab-content w-100 overflow-auto" id="pills-tabContent">
				<div class="tab-pane fade show active w-100" id="pills-exchange" role="tabpanel" aria-labelledby="pills-exchange-tab" tabindex="0" >
					<main class="main-all-sec-con d-flex flex-column justify-content-start">
						<div class="tab-2-content-sec-1">
							<div class="container p-0">
								<div class="tab-content" id="pills-tabContent">
									<div class="tab-pane fade show active" id="pills-in-play" role="tabpanel" aria-labelledby="pills-in-play-tab" tabindex="0" >
										<div class="tab-2-content-sec-1-inner-con">
											<div class="tab-2-content-sec-1-head-sec d-flex align-items-center justify-content-between flex-nowrap" >
												
											</div>
											<div class="row  m-4 ">
												<p class="h1 text-center ">Coming Soon! </p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</main>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import SessionService from "@/services/SessionService";
import HeaderComponent from "@/components/Header.vue";
import SideBar from "@/components/SideBar.vue";
import SideBet from '@/components/SideBet.vue'
// import $ from 'jquery'
export default {
	name: "homeAll",
	components: { HeaderComponent, SideBar , SideBet},
	data() {
		return {
			publicPath: process.env.BASE_URL,
			auth: {},
			refreshHeaderKey : 0
		};
	},
	created: function () {
		this.auth = SessionService.getUser();
	},
	methods: {
		updateHeader(){
			this.refreshHeaderKey += 1;
		},
	},
};
</script>
