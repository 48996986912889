<template>
    <HeaderComponent :key="refreshHeaderKey" />
  
      <!-- Login Modal -->
      <div
        class="modal fade"
        id="loginModal"
        tabindex="-1"
        aria-labelledby="loginModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="loginModalLabel">Login</h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <form>
                <div class="mb-3">
                  <label for="username" class="form-label">Username</label>
                  <input
                    type="text"
                    class="form-control"
                    id="username"
                    placeholder="Enter your username"
                  />
                </div>
                <div class="mb-3">
                  <label for="password" class="form-label">Password</label>
                  <input
                    type="password"
                    class="form-control"
                    id="password"
                    placeholder="Enter your password"
                  />
                </div>
              </form>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button type="button" class="btn btn-primary">Login</button>
            </div>
          </div>
        </div>
      </div>
      <div class=" p-0" >
        <div class="main-con d-flex">
          <SideBar />
  
          <SideBet @changeKeyHeader="updateHeader()"/>
  
          <div class="tab-content w-100 overflow-auto" id="pills-tabContent">
            <div
              class="tab-pane fade show active w-100"
              id="pills-exchange"
              role="tabpanel"
              aria-labelledby="pills-exchange-tab"
              tabindex="0"
            >
              <main
                class="main-all-sec-con d-flex flex-column justify-content-start"
              >
              <p class="moving-text">CHEATING OF ANY KIND SUCH AS MARKET MANIPULATION,SNIPING,COURT-SIDING,COMMISSION ABUSE & BETS AFTER EVENT CLOSED ARE NOT ALLOWED ON ANY SITE OPERATING ON THIS PLATFORM.THIS SITE RESERVES THE RIGHT TO VOID THE BETS IN QUESTION WITHOUT WARNING !</p>
              <swiper
                  :pagination="{
                    dynamicBullets: true,
                  }"
                  :autoplay="{
                    delay: 2500,
                    disableOnInteraction: false,
                  }"
                  :modules="modules"
                  class="mySwiper"
                >
                  <swiper-slide>
                    <img src="https://crm.ss8055.com/sports/banners/825.png" alt="" />
                  </swiper-slide>
                  <swiper-slide>
                    <img src="https://crm.ss8055.com/sports/banners/826.png" alt="" />
                  </swiper-slide>
                  <swiper-slide>
                    <img src="https://crm.ss8055.com/sports/banners/827.png" alt="" />
                  </swiper-slide>
                  <swiper-slide>
                    <img src="https://crm.ss8055.com/sports/banners/828.png" alt="" />
                  </swiper-slide>
                </swiper>
                <div class="tab-2-content-sec-1">
                <div class="container p-0">
                  <div class="tab-content " id="pills-tabContent">
                    <div
                      class="tab-pane fade cricket-pills show active"
                      
                      role="tabpanel"
                      aria-labelledby="pills-in-play-tab"
                      tabindex="0"
                    >
                      <div class="tab-2-content-sec-1-inner-con">
                        <div
                          class="tab-2-content-sec-1-head-sec d-flex align-items-center justify-content-between flex-nowrap"
                        >
                          <h3 class="tab-2-content-sec-1-head">Live Cricket</h3>
                        </div>
                        <div class="tab-2-content-sec-1-inner-con-table-con" v-if="events && events.length > 0">
                          <table class="table table-striped dark-table">
                              <tr v-for=" (event, index) in events" v-bind:index="index" v-bind:key="event.id" class="bg-white">
                                <td>
                                  <router-link :to="{name:'event.detail',params: { id: event.id, event_type: 'cricket' }, state: { event_type: 'cricket' } }" class="text-1 live"><span>Live</span></router-link>
                                </td>
                                <th @click="this.reddirectToEvent(event.id, 'cricket')"><span class="text-2 game-name" style="text-decoration: underline;">{{event.home}}</span></th>
                                <th @click="this.reddirectToEvent(event.id, 'cricket')"><span class="text-2 vs" style="text-decoration: underline;">Vs</span></th>
                                <th @click="this.reddirectToEvent(event.id, 'cricket')"><span class="text-2 game-name" style="text-decoration: underline;">{{event.away}}</span></th>
                                <td @click="this.reddirectToEvent(event.id, 'cricket')"><span class="text-3 date" >{{ event.date }}</span></td>
                              </tr>
                          </table>
                        </div>
                        <div class="tab-2-content-sec-1-inner-con-table-con text-center" v-else>
                          <span class="p-2 d-block">No Event available.</span>
                        </div>
                      </div>
                      <div class="tab-2-content-sec-1-inner-con">
                        <div
                          class="tab-2-content-sec-1-head-sec d-flex align-items-center justify-content-between flex-nowrap"
                        >
                          <h3 class="tab-2-content-sec-1-head">Live Soccer</h3>
                        </div>
                        <div class="tab-2-content-sec-1-inner-con-table-con" v-if="soccerLive && soccerLive.length > 0">
                          <table class="table table-striped dark-table">
                              <tr v-for=" (event, index) in soccerLive" v-bind:index="index" v-bind:key="event.id" class="bg-white">
                                <td>
                                  <router-link :to="{name:'event.detail',params: { id: event.id, event_type: 'soccer' }, state: { event_type: 'soccer' }}" class="text-1 live"><span>Live</span></router-link>
                                </td>
                                <th @click="this.reddirectToEvent(event.id, 'soccer')"><span class="text-2 game-name" style="text-decoration: underline;">{{event.home}}</span></th>
                                <th @click="this.reddirectToEvent(event.id, 'soccer')"><span class="text-2 vs" style="text-decoration: underline;">Vs</span></th>
                                <th @click="this.reddirectToEvent(event.id, 'soccer')"><span class="text-2 game-name" style="text-decoration: underline;">{{event.away}}</span></th>
                                <td @click="this.reddirectToEvent(event.id, 'soccer')"><span class="text-3 date" >{{ event.date }}</span></td>
                              </tr>
                          </table>
                        </div>
                        <div class="tab-2-content-sec-1-inner-con-table-con text-center" v-else>
                          <span class="p-2 d-block">No Event available.</span>
                        </div>
                      </div>
                      <div class="tab-2-content-sec-1-inner-con">
                        <div
                          class="tab-2-content-sec-1-head-sec d-flex align-items-center justify-content-between flex-nowrap"
                        >
                          <h3 class="tab-2-content-sec-1-head">Live Tennis</h3>
                        </div>
                        <div class="tab-2-content-sec-1-inner-con-table-con" v-if="tennisLive && tennisLive.length > 0">
                          <table class="table table-striped dark-table">
                              <tr v-for=" (event, index) in tennisLive" v-bind:index="index" v-bind:key="event.id" class="bg-white">
                                <td>
                                  <router-link :to="{name:'event.detail',params: { id: event.id, event_type: 'tennis' }, state: { event_type: 'tennis' }}" class="text-1 live"><span>Live</span></router-link>
                                </td>
                                <th @click="this.reddirectToEvent(event.id, 'tennis')"><span class="text-2 game-name" style="text-decoration: underline;">{{event.home}}</span></th>
                                <th @click="this.reddirectToEvent(event.id, 'tennis')"><span class="text-2 vs" style="text-decoration: underline;">Vs</span></th>
                                <th @click="this.reddirectToEvent(event.id, 'tennis')"><span class="text-2 game-name" style="text-decoration: underline;">{{event.away}}</span></th>
                                <td @click="this.reddirectToEvent(event.id, 'tennis')"><span class="text-3 date" >{{ event.date }}</span></td>
                              </tr>
                          </table>
                        </div>
                        <div class="tab-2-content-sec-1-inner-con-table-con text-center" v-else>
                          <span class="p-2 d-block">No Event available.</span>
                        </div>
                      </div>
                    </div>
                    <div
                      class="tab-pane fade"
                      id="pills-cup-winner"
                      role="tabpanel"
                      aria-labelledby="pills-cup-winner-tab"
                      tabindex="0"
                    >
                      <div class="tab-2-content-sec-1-inner-con">
                        <div
                          class="tab-2-content-sec-1-head-sec d-flex align-items-center justify-content-between flex-nowrap"
                        >
                        Coming Soon!
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
                
  
                <div class="tab-2-content-ammount-set-sec">
                  <div class="container">
                    <div
                      class="tab-2-content-ammount-set-sec-outer d-flex flex-wrap"
                    >
                      <h3 class="tab-2-content-ammount-set-sec-head mb-3 mb-xl-0">
                        50 Over Relay
                      </h3>
                      <div class="tab-2-content-ammount-set-sec-content-con">
                        <div
                          class="tab-2-content-ammount-set-sec-content-con-inner d-flex"
                        >
                          <span class="btn-click-value-display">2.10</span>
                          <form
                            action="#"
                            class="tab-2-content-ammount-set-sec-form d-flex text-start"
                          >
                            <input
                              class="enter-bet-ammount"
                              type="text"
                              placeholder="Enter Bet Ammount"
                            />
                            <input class="clear" type="reset" value="Clear" />
                            <input
                              class="place-bet"
                              type="submit"
                              value="Place Bet"
                            />
                          </form>
                        </div>
                      </div>
                    </div>
                    <div
                      class="tab-2-content-ammount-set-sec-outer-2 d-flex mt-3 flex-nowrap"
                    >
                      <span class="add-aamount-btn">10</span>
                      <span class="add-aamount-btn">20</span>
                      <span class="add-aamount-btn">50</span>
                      <span class="add-aamount-btn">100</span>
                      <span class="add-aamount-btn">500</span>
                      <span class="add-aamount-btn">1000</span>
                    </div>
                  </div>
                </div>
  
                <div class="tab-3-sec-outer-con d-flex flex-column flex-nowrap">
                  <div class="tab-3-sec">
                    <ul
                      class="nav nav-underline flex-nowrap"
                      id="pills-tab"
                      role="tablist"
                    >
                      <li class="nav-item" role="presentation">
                        <button
                          class="nav-link active"
                          id="pills-all-casinos-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-all-casinos"
                          type="button"
                          role="tab"
                          aria-controls="pills-all-casinos"
                          aria-selected="true"
                        >
                          All Casinos
                        </button>
                      </li>
                      <li class="nav-item" role="presentation">
                        <button
                          class="nav-link"
                          id="pills-teenpatti-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-teenpatti"
                          type="button"
                          role="tab"
                          aria-controls="pills-teenpatti"
                          aria-selected="false"
                        >
                          Teenpatti
                        </button>
                      </li>
                      <li class="nav-item" role="presentation">
                        <button
                          class="nav-link"
                          id="pills-teenpatti-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-teenpatti"
                          type="button"
                          role="tab"
                          aria-controls="pills-teenpatti"
                          aria-selected="false"
                        >
                          Baccarat
                        </button>
                      </li>
                      <li class="nav-item" role="presentation">
                        <button
                          class="nav-link"
                          id="pills-teenpatti-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-teenpatti"
                          type="button"
                          role="tab"
                          aria-controls="pills-teenpatti"
                          aria-selected="false"
                        >
                          Poker
                        </button>
                      </li>
                      <li class="nav-item" role="presentation">
                        <button
                          class="nav-link"
                          id="pills-teenpatti-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-teenpatti"
                          type="button"
                          role="tab"
                          aria-controls="pills-teenpatti"
                          aria-selected="false"
                        >
                          Roulette
                        </button>
                      </li>
                      <li class="nav-item" role="presentation">
                        <button
                          class="nav-link"
                          id="pills-teenpatti-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-teenpatti"
                          type="button"
                          role="tab"
                          aria-controls="pills-teenpatti"
                          aria-selected="false"
                        >
                          Card Casino
                        </button>
                      </li>
                      <li class="nav-item" role="presentation">
                        <button
                          class="nav-link"
                          id="pills-teenpatti-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-teenpatti"
                          type="button"
                          role="tab"
                          aria-controls="pills-teenpatti"
                          aria-selected="false"
                        >
                          7 Up Down
                        </button>
                      </li>
                      <li class="nav-item" role="presentation">
                        <button
                          class="nav-link"
                          id="pills-teenpatti-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-teenpatti"
                          type="button"
                          role="tab"
                          aria-controls="pills-teenpatti"
                          aria-selected="false"
                        >
                          Andar Bahar
                        </button>
                      </li>
                      <li class="nav-item" role="presentation">
                        <button
                          class="nav-link"
                          id="pills-teenpatti-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-teenpatti"
                          type="button"
                          role="tab"
                          aria-controls="pills-teenpatti"
                          aria-selected="false"
                        >
                          High Low
                        </button>
                      </li>
                      <li class="nav-item" role="presentation">
                        <button
                          class="nav-link"
                          id="pills-teenpatti-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-teenpatti"
                          type="button"
                          role="tab"
                          aria-controls="pills-teenpatti"
                          aria-selected="false"
                        >
                          Dragon Tiger
                        </button>
                      </li>
                      <li class="nav-item" role="presentation">
                        <button
                          class="nav-link"
                          id="pills-teenpatti-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-teenpatti"
                          type="button"
                          role="tab"
                          aria-controls="pills-teenpatti"
                          aria-selected="false"
                        >
                          Amar Akbar Anthony
                        </button>
                      </li>
                      <li class="nav-item" role="presentation">
                        <button
                          class="nav-link"
                          id="pills-teenpatti-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-teenpatti"
                          type="button"
                          role="tab"
                          aria-controls="pills-teenpatti"
                          aria-selected="false"
                        >
                          Card Race
                        </button>
                      </li>
                      <li class="nav-item" role="presentation">
                        <button
                          class="nav-link"
                          id="pills-teenpatti-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-teenpatti"
                          type="button"
                          role="tab"
                          aria-controls="pills-teenpatti"
                          aria-selected="false"
                        >
                          Matka
                        </button>
                      </li>
                      <li class="nav-item" role="presentation">
                        <button
                          class="nav-link"
                          id="pills-teenpatti-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-teenpatti"
                          type="button"
                          role="tab"
                          aria-controls="pills-teenpatti"
                          aria-selected="false"
                        >
                          Sic Bo
                        </button>
                      </li>
                    </ul>
                  </div>
                  <div class="tab-content" id="pills-tabContent">
                    <div
                      class="tab-pane fade show active"
                      id="pills-all-casinos"
                      role="tabpanel"
                      aria-labelledby="pills-all-casinos-tab"
                      tabindex="0"
                    >
                      <div
                        class="tab-3-content-sec-1 d-flex flex-nowrap align-items-center"
                      >
                        <a href="#">
                          <div class="tab-3-content-sec-1-img-con">
                            <img src="assets/img/game-image-1.svg" alt="" />
                            <span class="tab-3-content-sec-1-img-con-text"></span>
                          </div>
                        </a>
                        <a href="#">
                          <div class="tab-3-content-sec-1-img-con">
                            <img src="assets/img/game-image-2.svg" alt="" />
                            <!-- <span class="tab-3-content-sec-1-img-con-text"></span> -->
                          </div>
                        </a>
                        <a href="#">
                          <div class="tab-3-content-sec-1-img-con">
                            <img src="assets/img/game-image-3.svg" alt="" />
                            <!-- <span class="tab-3-content-sec-1-img-con-text"></span> -->
                          </div>
                        </a>
                        <a href="#">
                          <div class="tab-3-content-sec-1-img-con">
                            <img src="assets/img/game-image-4.svg" alt="" />
                            <!-- <span class="tab-3-content-sec-1-img-con-text"></span> -->
                          </div>
                        </a>
                        <a href="#">
                          <div class="tab-3-content-sec-1-img-con">
                            <img src="assets/img/game-image-5.svg" alt="" />
                            <!-- <span class="tab-3-content-sec-1-img-con-text"></span> -->
                          </div>
                        </a>
                      </div>
                    </div>
                    <div
                      class="tab-pane fade"
                      id="pills-teenpatti"
                      role="tabpanel"
                      aria-labelledby="pills-teenpatti-tab"
                      tabindex="0"
                    >
                      <div
                        class="tab-3-content-sec-1 d-flex flex-nowrap align-items-center"
                      >
                        <a href="#">
                          <div class="tab-3-content-sec-1-img-con">
                            <img src="assets/img/game-image-1.svg" alt="" />
                            <span class="tab-3-content-sec-1-img-con-text"></span>
                          </div>
                        </a>
                        <a href="#">
                          <div class="tab-3-content-sec-1-img-con">
                            <img src="assets/img/game-image-2.svg" alt="" />
                            <!-- <span class="tab-3-content-sec-1-img-con-text"></span> -->
                          </div>
                        </a>
                        <a href="#">
                          <div class="tab-3-content-sec-1-img-con">
                            <img src="assets/img/game-image-3.svg" alt="" />
                            <!-- <span class="tab-3-content-sec-1-img-con-text"></span> -->
                          </div>
                        </a>
                        <a href="#">
                          <div class="tab-3-content-sec-1-img-con">
                            <img src="assets/img/game-image-4.svg" alt="" />
                            <!-- <span class="tab-3-content-sec-1-img-con-text"></span> -->
                          </div>
                        </a>
                        <a href="#">
                          <div class="tab-3-content-sec-1-img-con">
                            <img src="assets/img/game-image-5.svg" alt="" />
                            <!-- <span class="tab-3-content-sec-1-img-con-text"></span> -->
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </main>
            </div>
  
            <div
              class="tab-pane fade"
              id="pills-ezugi"
              role="tabpanel"
              aria-labelledby="pills-ezugi-tab"
              tabindex="0"
            >
              <h1 class="text-center m-5 p-0" style="font-size: 30px">Coming Soon!</h1>
            </div>
          </div>
        </div>
      </div>
  </template>
  
  <script>
  
  // import UserHome from './user/UserHome.vue'
  // import AdminHome from './admin/AdminHome.vue'
  import SessionService from "@/services/SessionService";
  import DataService from "@/services/DataService.js";
  import HeaderComponent from "@/components/Header.vue";
  import SideBar from '@/components/SideBar.vue'
  import SideBet from '@/components/SideBet.vue'
  import { Swiper, SwiperSlide } from 'swiper/vue';
  // Import Swiper styles
  import 'swiper/css';
  import 'swiper/css/pagination';
  import { Autoplay, Pagination, Navigation } from 'swiper/modules';

  // import axios from "axios";
  import io from "socket.io-client";
  
  // import $ from 'jquery'
  export default {
      name: 'homeAll',
    components : {
      HeaderComponent , SideBar , SideBet,  Swiper, SwiperSlide
    },
      data() {
      return {
              publicPath: process.env.BASE_URL,
        auth : {},
        events : [],
        soccerLive : [],
        tennisLive : [],
        intervalId : null,
        refreshHeaderKey : 0
      }
      },
      // components: {
      // 	UserHome,AdminHome
      // },
      created: function () {
          this.auth = SessionService.getUser();
      // this.fetchDataAllEvents()
      const socket = io.connect(process.env.VUE_APP_SOCKET_URL);
          socket.on('allEventList', (response) => {
          const data = response.data;
          const status = response.status;
          if(status){
            this.events = data.live;
            this.upcomingEvents = data.upcoming;
            this.soccerLive = data.soccerLive;
            this.tennisLive = data.tennisLive;
          }
          console.log(response)
          })
      
      },
    unmounted() {
      this.clearAllIntervals();
    },
    beforeUnmount() {
      this.clearAllIntervals();
    },
    setup() {
      return {
        modules: [Autoplay, Pagination, Navigation],
      };
    },
      methods:{
      updateHeader(){
        this.refreshHeaderKey += 1;
      },
      reddirectToEvent(event_id){
        if(event_id){
          // console.log('event_id',event_id)
          this.$router.push({name:'event.detail',params: { id: event_id }});		
        }
        
      },
          logOut(){	
              DataService.logout().then(response => {
                  if (response.status == 200) {					
                      localStorage.removeItem('user');
                      this.$router.push({ name: 'login' });		
                  }
              })
              .catch(e => {
                  console.log('error', e)
                  this.isLoading = false
              });
          },
      // fetchDataAllEvents(){
      //   axios.post(`${process.env.VUE_APP_SOCKET_URL}/events`)
      //   .then(response => {
      //     const data = response.data.data
      //     const status = response.data.status
      //     if(status){
      //       // console.log('data' , data)
      //       this.events = data.live
      //       this.upcomingEvents = data.upcoming
      //     }
      //   })
      //   .catch(error => {
      //     console.error('Error:', error);
      //   });
      // },
      clearAllIntervals() {
        if (this.intervalId) {
          clearInterval(this.intervalId);
        }
      },
      },
      // sockets: {
      // 	eventList : function(events){
      // 		// console.log('events',events)
    //     this.events = events
      // 	},
    //   upcomingEventList:function(upcomingEvent){
        
    //     this.upcomingEvents = upcomingEvent
    //   }
      // }
  }
  </script>