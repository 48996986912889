<template>
  <section class="login-form-info">
    <div class="lgoin-form-wrap w-100 m-auto">
      <div class="logo text-center">
        <img
          :src="`${publicPath}assets/img/brand-logo.svg`"
          alt=""
          style="height: 80px"
        />
        <div class="login-header mt-5">
          <h1>Welcome!</h1>
        </div>
      </div>
      <div class="col-md-12">
        <form
          @submit.prevent="handleSubmit"
          ref="form"
          id="blog_save_form"
          enctype="multipart/form-data"
        >
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label for="email">User Name:</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="User Name"
                  name="username"
                  v-model="username"
                  ref="emailInput"
                />
                <span class="error" style="color: red">{{
                  error.username
                }}</span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label for="pwd">Password:</label>
                <input
                  :type="this.passwordType"
                  class="form-control"
                  name="password"
                  id="password"
                  v-model="password"
                  placeholder="Enter password"
                  @paste="preventPasswordPaste"
                />
                <span class="error" style="color: red">{{
                  error.password
                }}</span>
              </div>
            </div>
          </div>
          <button type="submit" class="btn btn-primary btn-login">Login</button>
        </form>

        <div class="login-link text-center mt-4 d-flex justify-content-between">
          <a href="#">Contact admin for new Account</a>
          <a href="#">Forgot Password?</a>
        </div>
      </div>
    </div>
  </section>

  <!-- <section class="login">
		<div class="container">
			<div class="login-form--wrap">
				<div class="login-form">
					<div style="text-align: center;">
						
					</div>
					<h2>Log in</h2>
					<div class="col-md-12">
						<form @submit.prevent="handleSubmit" ref="form" id="blog_save_form" enctype="multipart/form-data">
							<div class="row">
								<div class="col-md-12">
									<div class="form-group">
										<label for="email">User Name:</label>
										<input type="text" class="form-control" placeholder="User Name"  name="username" v-model="username"  ref="emailInput">
										<span class="error" style="color:red;">{{ error.username }}</span>
									</div>
								</div>
							</div>	
							<div class="row">
								<div class="col-md-12">
									<div class="form-group">
										<label for="pwd">Password:</label>
										<input :type="this.passwordType" class="form-control" name="password"   id="password" v-model="password"  placeholder="Enter password" >
										<span class="error" style="color:red;">{{ error.password }}</span>
									</div>
								</div>
							</div>	
							<button type="submit" class="btn btn-primary">Login</button>
						</form>
					</div>
				</div>
			</div>
		</div>
	</section> -->
</template>
<!-- <style scoped>
	@import '../assets/css/bootstrap.min.css';
	@import '../assets/fonts/fonts.css';
	@import '../assets/css/style.css';
	@import '../assets/css/slick.css';
	@import '../assets/css/responsive.css';
	
	</style> -->
<style>
.login-form--wrap {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-form {
  width: 100%;
  max-width: 650px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 20px 30px;
}
.login-form h2 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}
.login-form form .row {
  width: 100%;
}
.login-form form label {
  font-size: 18px;
  font-weight: 500;
  padding-bottom: 10px;
}

.error {
  display: block;
  margin-top: 10px;
  font-weight: bold;
  margin-left: 5px;
}
</style>
<script>
import DataService from "@/services/DataService.js";
import cryoptojs from "crypto-js";
// import SessionService from "@/services/SessionService";
// import SessionService from "@/services/SessionService";

export default {
  name: "loginAll",
  data() {
    return {
      publicPath: process.env.BASE_URL,
      username: "",
      password: "",
      submitbtn: "Login",
      submitted: false,
      checkedPassword: false,
      passwordType: "password",
      error: [],
    };
  },
  computed: {},
  mounted() {
    this.$refs.emailInput.focus();
  },
  methods: {
    preventPasswordPaste(event) {
      event.preventDefault();
      this.$toast.error('Pasting password is not allowed');
    },
    handleSubmit() {
      this.submitted = true;
      const { username, password } = this;

      const data = {
        username,
        password,
      };
      DataService.login(data)
        .then((response) => {
          if (response.status == 200) {
            const encryptedText = cryoptojs.AES.encrypt(
              JSON.stringify(response.data.data),
              "vx"
            ).toString();
            localStorage.setItem("user", encryptedText);
            // location.reload();
            if(response.data.data.is_reset == '1'){
              window.location = "/profile";
            } else {
              window.location = "/";
            }
          }
        })
        .catch((e) => {
          console.log("error", e);
          this.isLoading = false;
          if (e.response.status == 401) {
            const data = e.response.data;
            this.error = [];
            if (data.error) {
              if (data.error == "block") {
                this.$toast.error(data.message);
              }
            }
          }
          if (e.response.status == 403) {
            const data = e.response.data;
            this.error = [];

            if (data.error) {
              if (data.error.username) {
                this.error["username"] = data.error.username[0];
              }
              if (data.error.password) {
                this.error["password"] = data.error.password[0];
              }
            }
          }
        });
    },
    passwordHideShow() {
      if (this.checkedPassword) {
        this.passwordType = "text";
      } else {
        this.passwordType = "password";
      }
    },
  },
  sockets: {
    // loginUser:function (Token){
    // 	setTimeout( () => {
    // 		const user = SessionService.getUser()
    // 		if(user){
    // 			if(user.token === Token){
    // 				window.location = "/";
    // 			}
    // 		}
    // 	},250)
    // }
  },
};
</script>
