<template>
	<nav class="main-navbar navbar navbar-expand-lg p-0 flex-column align-items-start">
    <button
      class="navbar-toggler align-items-start"
      id="openNav"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarNavDropdown"
      aria-controls="navbarNavDropdown"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div
      class="collapse navbar-collapse sidepanel active align-items-start justify-content-start"
      id="navbarNavDropdown">
      <ul class="navbar-nav d-flex flex-column">
        <li class="nav-item">
          
          <router-link class="nav-link" :to="{ name: 'home.all' }" >
            <img :src="`${publicPath}assets/img/home.svg`" alt="" />Home
          </router-link>
        </li>
        <li class="nav-item">
          <router-link class="nav-link" :to="{ name: 'bets' }" >
            <img :src="`${publicPath}assets/img/rules.svg`"  alt="" />Bets
          </router-link>
        </li>
        <!-- <li class="nav-item dropdown">
          <a
            
            
            :class="`nav-link dropdown-toggle active ${($route.name === 'account-statement' || $route.name === 'pl-statement') ? 'show' : ''}`"
            href="#"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <img :src="`${publicPath}assets/img/masters.svg`" alt="" />Statement
          </a>
          <ul  :class="`dropdown-menu active ${($route.name === 'account-statement' || $route.name === 'pl-statement') ? 'show' : ''}`">
            <li>
              
              <router-link class="dropdown-item" :to="{ name: 'account-statement' }" >
                Account Statement
              </router-link>
            </li>
            <li>
              
              <router-link class="dropdown-item" :to="{ name: 'pl-statement' }" >
                PL Statement
              </router-link>
            </li>
          </ul>
        </li> -->
        <li class="nav-item">
          <router-link :to="{ name: 'account-statement' }" class="nav-link"><img :src="`${publicPath}assets/img/masters.svg`" alt="" />Account Statement</router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'pl-statement' }" class="nav-link"><img :src="`${publicPath}assets/img/masters.svg`" alt="" />PL Statement</router-link>
        </li>
        <!-- <li class="nav-item">
          <router-link :to="{ name: 'comingsoon' }" class="nav-link"><img :src="`${publicPath}assets/img/client-listing.svg`" alt="" />Client
            Listing</router-link>
        </li> -->
        <li class="nav-item">
          
          <router-link :to="{ name: 'comingsoon-favorites' }" class="nav-link"><img :src="`${publicPath}assets/img/favorites.svg`" alt="" />Favorites</router-link>
        </li>

        <li class="nav-item">
          
          <router-link :to="{ name: 'scorecard' }" class="nav-link"><img :src="`${publicPath}assets/img/score.png`" alt="" />Scorecard</router-link>
        </li>

        <!-- <li class="nav-item dropdown">
          <a
            class="nav-link dropdown-toggle active"
            href="#"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <img :src="`${publicPath}assets/img/in-play.svg`" alt="" />In-Playhh
          </a>
          
          <ul class="dropdown-menu">
            <li>
              <router-link :to="{ name: 'comingsoon' }" class="dropdown-item active link-comingsoon">
                Al Michelsen v Seongc Hong
              </router-link>
            </li>
            <li>
              
              <router-link :to="{ name: 'comingsoon' }" class="dropdown-item active link-comingsoon">
                Kath Barnes v Giu Pairone
              </router-link>
            </li>
            <li>
              
              <router-link :to="{ name: 'comingsoon' }" class="dropdown-item active link-comingsoon">
                Breckova v Nik Liebkind
              </router-link>
            </li>
            <li>
              
              <router-link :to="{ name: 'comingsoon' }" class="dropdown-item active link-comingsoon">
                Mi Damas v Luz
              </router-link>
            </li>
          </ul>
        </li> -->
        <li class="nav-item">
          <router-link :to="{ name: 'inplay' }" class="nav-link"><img :src="`${publicPath}assets/img/in-play.svg`" alt="" />In-Play</router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'cricket.all' }" class="nav-link"><img :src="`${publicPath}assets/img/cricket.svg`" alt="" />Cricket</router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'soccer.all' }" class="nav-link"><img :src="`${publicPath}assets/img/soccer.svg`" alt="" />Soccer</router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'tennis.all' }" class="nav-link"><img :src="`${publicPath}assets/img/tennis.svg`" alt="" />Tennis</router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'comingsoon-horse-racing' }" class="nav-link"><img :src="`${publicPath}assets/img/horse-racing.svg`" alt="" />Horse Racing</router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'comingsoon-indian-racing' }" class="nav-link"><img :src="`${publicPath}assets/img/indian-racing.svg`" alt="" />Indian Racing</router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'comingsoon-grey-hound' }" class="nav-link"><img :src="`${publicPath}assets/img/grey-hound.svg`" alt="" />Grey Hound</router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'comingsoon-election' }" class="nav-link"><img :src="`${publicPath}assets/img/election.svg`" alt="" />Election</router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'comingsoon-game' }" class="nav-link"><img :src="`${publicPath}assets/img/election.svg`" alt="" />Game</router-link>
        </li>
        
        <!-- <li class="nav-item dropdown">
          <a
            class="nav-link dropdown-toggle"
            href="#"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <img :src="`${publicPath}assets/img/election.svg`" alt="" />Game
          </a>
          <ul class="dropdown-menu">
            <li v-for=" (game, index) in userGame" v-bind:index="index" v-bind:key="index">
              <a class="dropdown-item " href="javascript:void(0)"
                >{{ game?.name }}</a
              >
            </li>
          </ul>
        </li> -->
      </ul>
    </div>
    <div class="dark" id="darklayer"></div>
  </nav>
</template>

<script>

// import UserHome from './user/UserHome.vue'
// import AdminHome from './admin/AdminHome.vue'
import SessionService from "@/services/SessionService";
import DataService from "@/services/DataService.js";
// import $ from 'jquery'
export default {
  name: 'HeaderAll',
  data() {
    return {
      publicPath: process.env.BASE_URL,
      auth: {},
      userGame: {}

    }
  },
  // components: {
  // 	UserHome,AdminHome
  // },
  created: function () {
    this.auth = SessionService.getUser();
    this.userGames()
    // $(document).ready(function () {
    //   $('.sidenavCollapse').on('click', function () {
    //       $('.sidepanel').toggleClass('active');
    //   });
    // });
  },
  methods: {
    handleHeaderButtonClick() {
      console.log('in sidebar')
    },
    userGames() {
      DataService.getUserAllowedGames().then((response) => {
        if (response.status == 200 && response.data.status) {
          const data = response.data.data
          this.userGame = data
        }
      }).catch((e) => {
        console.log('error', e)
      })
    },

  },
}
</script>