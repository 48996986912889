<template>
  <HeaderComponent :key="this.refreshHeaderKey" />

  <!-- Login Modal -->
  <div
    class="modal fade"
    id="loginModal"
    tabindex="-1"
    aria-labelledby="loginModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="loginModalLabel">Login</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form>
            <div class="mb-3">
              <label for="username" class="form-label">Username</label>
              <input
                type="text"
                class="form-control"
                id="username"
                placeholder="Enter your username"
              />
            </div>
            <div class="mb-3">
              <label for="password" class="form-label">Password</label>
              <input
                type="password"
                class="form-control"
                id="password"
                placeholder="Enter your password"
              />
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button>
          <button type="button" class="btn btn-primary">Login</button>
        </div>
      </div>
    </div>
  </div>
  <div class="p-0">
    <div class="main-con d-flex">
      <SideBar />
      <SideBet @changeKeyHeader="updateHeader()" />
      <div class="tab-content w-100 overflow-auto" id="pills-tabContent">
        <div
          class="tab-pane fade show active w-100"
          id="pills-exchange"
          role="tabpanel"
          aria-labelledby="pills-exchange-tab"
          tabindex="0"
        >
          <main
            class="main-all-sec-con d-flex flex-column justify-content-start"
          >
            <div class="tab-2-content-sec-1">
              <div class="container p-0">
                <div class="tab-content" id="pills-tabContent">
                  <div
                    class="tab-pane fade show active"
                    id="pills-in-play"
                    role="tabpanel"
                    aria-labelledby="pills-in-play-tab"
                    tabindex="0"
                  >
                    <div class="tab-2-content-sec-1-inner-con overflow-hidden">
                      <div
                        class="tab-2-content-sec-1-head-sec d-flex align-items-center justify-content-between flex-nowrap"
                      >
                        <h3 class="tab-2-content-sec-1-head">Stack Button</h3>
                      </div>
                      <form @submit.prevent="handleSubmit">
                        <div class="row p-2">
                          <div class="form-group col-md-4">
                            <label for="button1">Stake 1</label>
                            <input
                              type="text"
                              id="button1"
                              :class="`form-control ${
                                error.button_1 ? 'is-invalid' : ''
                              }`"
                              v-model="this.button_1"
                            />
                            <div class="invalid-feedback">
                              {{ error?.button_1 }}
                            </div>
                          </div>
                          <div class="form-group col-md-4">
                            <label for="button2">Stake 2</label>
                            <input
                              type="text"
                              id="button2"
                              :class="`form-control ${
                                error.button_2 ? 'is-invalid' : ''
                              }`"
                              v-model="this.button_2"
                            />
                            <div class="invalid-feedback">
                              {{ error?.button_2 }}
                            </div>
                          </div>
                          <div class="form-group col-md-4">
                            <label for="button3">Stake 3</label>
                            <input
                              type="text"
                              id="button3"
                              :class="`form-control ${
                                error.button_3 ? 'is-invalid' : ''
                              }`"
                              v-model="this.button_3"
                            />
                            <div class="invalid-feedback">
                              {{ error?.button_3 }}
                            </div>
                          </div>
                          <div class="form-group col-md-4">
                            <label for="button4">Stake 4</label>
                            <input
                              type="text"
                              id="button4"
                              :class="`form-control ${
                                error.button_4 ? 'is-invalid' : ''
                              }`"
                              v-model="this.button_4"
                            />
                            <div class="invalid-feedback">
                              {{ error?.button_4 }}
                            </div>
                          </div>
                          <div class="form-group col-md-4">
                            <label for="button5">Stake 5</label>
                            <input
                              type="text"
                              id="button5"
                              :class="`form-control ${
                                error.button_5 ? 'is-invalid' : ''
                              }`"
                              v-model="this.button_5"
                            />
                            <div class="invalid-feedback">
                              {{ error?.button_5 }}
                            </div>
                          </div>
                          <div class="form-group col-md-4">
                            <label for="default">Default Bet</label>
                            <input
                              type="text"
                              id="default"
                              :class="`form-control ${
                                error.default_button ? 'is-invalid' : ''
                              }`"
                              v-model="this.default_button"
                            />
                            <div class="invalid-feedback">
                              {{ error?.default_button }}
                            </div>
                          </div>
                          <div
                            class="form-group col-md-12 justify-content-center d-flex"
                          >
                            <button
                              style="color:#fff;"
                              id="filterByDate"
                              class="btn btn-primary"
                              type="submit"
                            >
                              Update Stake
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
        <div
          class="tab-pane fade"
          id="pills-ezugi"
          role="tabpanel"
          aria-labelledby="pills-ezugi-tab"
          tabindex="0"
        >
          <h1 class="text-center m-5 p-0" style="font-size: 30px">Hello</h1>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.form-group label + input {
  margin-top: 5px;
}
.form-group label + select {
  margin-top: 5px;
}
</style>

<script>
import SessionService from "@/services/SessionService";
import DataService from "@/services/DataService.js";
import "datatables.net-buttons/js/buttons.html5.js"
import "datatables.net-buttons/js/buttons.print.js"
import "datatables.net-buttons-dt/css/buttons.dataTables.css" //export button styling
import 'datatables.net-dt/css/dataTables.dataTables.css'; //
import HeaderComponent from "@/components/Header.vue";
import SideBar from "@/components/SideBar.vue";
import SideBet from "@/components/SideBet.vue";

export default {
  name: "homeAll",
  components: {
    HeaderComponent,
    SideBar,
    SideBet,
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      auth: {},
      button_1: "",
      button_2: "",
      button_3: "",
      button_4: "",
      button_5: "",
      default_button: "",
      isLoading: false,
      error: {},
      refreshHeaderKey: 0,
    };
  },
  // components: {
  // 	UserHome,AdminHome
  // },
  created: function () {
    this.auth = SessionService.getUser();
    this.getUserStackButtonData();
  },
  methods: {
    updateHeader() {
      this.refreshHeaderKey += 1;
    },
    logOut() {
      DataService.logout()
        .then((response) => {
          if (response.status == 200) {
            localStorage.removeItem("user");
            this.$router.push({ name: "login" });
          }
        })
        .catch((e) => {
          console.log("error", e);
          this.isLoading = false;
        });
    },
    getUserStackButtonData() {
      DataService.getUserStackButton()
        .then((response) => {
          if (response.status == 200) {
            const data = response.data.data;
            if (data) {
              this.button_1 = data.button_1;
              this.button_2 = data.button_2;
              this.button_3 = data.button_3;
              this.button_4 = data.button_4;
              this.button_5 = data.button_5;
              this.default_button = data.default_button;
            }
          }
        })
        .catch((e) => {
          console.log("error", e);
          this.isLoading = false;
        });
    },
    handleSubmit() {
      const data = {
        button_1: this.button_1,
        button_2: this.button_2,
        button_3: this.button_3,
        button_4: this.button_4,
        button_5: this.button_5,
        default_button: this.default_button,
      };
      this.error = {};
      DataService.userStoreStackButton(data)
        .then((response) => {
          if (response.status === 200) {
            if (response.data.status) {
              this.$toast.success(response.data.message);
            }
          }
        })
        .catch((e) => {
          console.log("error", e);
          this.isLoading = false;
          if (e.response.data.error) {
            const errors = e.response.data.error;
            let errorObj = {};
            Object.keys(errors).forEach((key) => {
              errorObj[key] = errors[key][0];
            });
            this.error = errorObj;
            this.$toast.error("Please Try Again.");
          }
        });
    },
  },
};
</script>
