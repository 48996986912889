<template>
  <router-view />
</template>

<script>
import SessionService from "@/services/SessionService";

export default {
	name: 'App',
	data(){
		return {
			publicPath: process.env.BASE_URL,
			auth: {
			},
		}
	},
	created: async function () {
		this.auth = await SessionService.getUser();
	},
	sockets: {
		UserActive : function(ID){
			const auth =  SessionService.getUser();
			if(auth.user._id === ID){
				console.log('okay')
			}else{
				console.log('not okay')
			}
		}
	}
}
</script>


