import SessionService from "@/services/SessionService";
export default (to, from, next) => {
	let auth = SessionService.getUser();
	
	if(!auth){
		next();
		return true;
		
	}else{
		next(from);
		return true;
	}
}