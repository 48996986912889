import http from "../http-common";
import { authHeader } from "../helpers";
// import axios from "axios";
// import { authHeader } from '../helpers';
// import { authHeader } from '../helpers';
// import { toast } from 'vue3-toastify';

http.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // toast.error('Something went wrong!', {
    //   theme : 'colored',
    //     autoClose: 1300
    // });
    if (error.response.status === 401) {
      const userData = localStorage.getItem("user");
      if (userData) {
        // console.log('userData',userData)
        localStorage.removeItem("user");
        localStorage.clear();
        window.location = "/";
      }
    }
    return Promise.reject(error);
  }
);
class DataService {
  userLogin(data) {
    return http.post(`/login`, data);
  }
  login(data) {
    return http.post(`/login`, data);
  }
  getUserTransctions(data) {
    return http.post(`/transactions/auth`, data);
  }
  getUserBets() {
    return http.get(`/bats/auth`);
  }
  getAuthToken() {
    return authHeader();
  }
  getUserBetsLink() {
    return `${process.env.VUE_APP_BACKEND_URL}/api/bats/auth`;
  }
  userPlaceBet(data) {
    return http.post(`/bats`, data);
  }
  getUserBalanace() {
    return http.post(`/user/balance`);
  }

  getBookData(marketId) {
    console.log('marketId: ', marketId);
    return [

      {
        score: 40,
        position: 0,
      },
      {
        score: 41,
        position: 1000,
      },
      {
        score: 42,
        position: 1000,
      },
      {
        score: 43,
        position: 1000,
      },
      {
        score: 44,
        position: 1000,
      },
      {
        score: 45,
        position: 1000,
      },
      {
        score: 46,
        position: 1000,
      },
      {
        score: 47,
        position: 1000,
      },
      {
        score: 48,
        position: 1000,
      },
      {
        score: 49,
        position: 1000,
      },
      {
        score: 50,
        position: 1000,
      },
      {
        score: 51,
        position: 0,
      },
      {
        score: 52,
        position: 0,
      },
      {
        score: 53,
        position: 0,
      },
    ]
  }

  getPLBalanace() {
    return http.post(`/user/pl-balance`);
  }

  getEventBetsList(data) {
    return http.post(`/bats/event`, data);
  }

  getUserStackButton() {
    return http.get(`/stack/auth`);
  }
  userStoreStackButton(data) {
    return http.post(`/stack-button`, data);
  }
  getEventLiabiliity(id) {
    return http.get(`/event-liabiliity/${id}`);
  }

  getEventoddsLiability(data) {
    return http.post(`/event-odds-liability`,data);
  }

  getEventBookDetail(data) {
    return http.post(`/event-book`, data);
  }
  getEventRule(game) {
    return http.get(`/rule/${game}`);
  }
  getUserAllowedGames() {
    return http.post(`/user/allowed-game`);
  }
  logout() {
    return http.post(`/logout`);
  }
  getUserAccountStatementLink() {
    return `${process.env.VUE_APP_BACKEND_URL}/api/user/account-statement`;
  }

  getUserAccountStatementByMarketLink(marketId) {
    return `${process.env.VUE_APP_BACKEND_URL}/api/user/account-statement/${marketId}`;
  }

  getUserPLStatementLink() {
    return `${process.env.VUE_APP_BACKEND_URL}/api/user/pl-statement`;
  }
  getUserTransctionsLink() {
    return `${process.env.VUE_APP_BACKEND_URL}/api/transactions/auth`;
  }
  getUserLiabilityLink() {
    return `${process.env.VUE_APP_BACKEND_URL}/api/auth/liability-list`;
  }
  updateBetUnmatched(id) {
    return http.post(`/bet/update-status/${id}`);
  }
  updateUserProfile(data) {
    return http.post(`/user/update-profile`, data);
  }
  getAuthDetail() {
    return http.post(`/auth/user`);
  }
  cancelUnmatchedBets(data) {
    return http.post(`/bet/unmatched-cancel`, data);
  }
  cancelAllUnmatchedBets(data) {
    return http.post(`/bet/unmatched-cancel-all`, data);
  }
  userCurrentLiability() {
    return http.post(`/auth/current-liability-list`);
  }
  getCompletedEventBetsList(data) {
    return http.post(`/completed-bats/event`, data);
  }
}

export default new DataService();
