import { createWebHistory, createRouter } from "vue-router";
import VueRouteMiddleware from "vue-route-middleware";
import AuthMiddleware from "../middleware/auth-middleware";
// import AdminMiddleware from "../middleware/admin-middleware";
// import SuperAdminMiddleware from "../middleware/superadmin-middleware";
import Home from "@/components/Home.vue";
import InPlay from "@/components/InPlay.vue";
import login from "@/components/login.vue";
import InnerEvent from "@/components/InnerEvent.vue";
import Transactions from "@/components/Transactions.vue";
import UserBetsList from "@/components/UserBetsList.vue";
import StackButton from "@/components/StackButton.vue";
import AccountStatement from "@/components/AccountStatement.vue";
import InnerAccountStatement from "@/components/InnerAccountStatement.vue";
import ProfitLossStatement from "@/components/ProfitLossStatement.vue";
import UserProfile from "@/components/UserProfile.vue";
import comingsoon from "@/components/comingsoon.vue";
import Liability from "@/components/Liability.vue";
import ScoreCard from "@/components/ScoreCard.vue";
import Soccer from "@/components/Soccer.vue";
import Tennis from "@/components/Tennis.vue";
import Cricket from "@/components/Cricket.vue";


const routes = [
  {
    path: "/login",
    name: "login",
    component: login,
    meta: {
      title: "VxExch | Login",
      middleware: [AuthMiddleware],
    },
  },
  {
    path: "/",
    name: "home.all",
    component: Home,
    meta: {
      title: "VxExch | Home",
    },
    props: true,
  },
  {
    path: "/",
    name: "inplay",
    component: InPlay,
    meta: {
      title: "VxExch | InPlay",
    },
  },
  {
    path: "/event/:event_type/:id",
    name: "event.detail",
    component: InnerEvent,
    meta: {
      title: "VxExch | Event ",
    },
    props: true,
  },
  {
    path: "/transactions",
    name: "transactions",
    component: Transactions,
    meta: {
      title: "VxExch | Transactions List",
    },
  },
  {
    path: "/bets",
    name: "bets",
    component: UserBetsList,
    meta: {
      title: "VxExch | Bets List",
    },
  },
  {
    path: "/stack-button",
    name: "stack-button",
    component: StackButton,
    meta: {
      title: "VxExch | Stack Button",
    },
  },
  {
    path: "/account-statement",
    name: "account-statement",
    component: AccountStatement,
    meta: {
      title: "VxExch | Account Statement",
    },
  },
  {
    path: "/account-statement/:id",
    name: "account-statement.detail",
    component: InnerAccountStatement,
    meta: {
      title: "Market Wise Account Statement ",
    },
  },
  {
    path: "/pl-statement",
    name: "pl-statement",
    component: ProfitLossStatement,
    meta: {
      title: "VxExch | Profit-Loss Statement",
    },
  },
  {
    path: "/profile",
    name: "user-profile",
    component: UserProfile,
    meta: {
      title: "VxExch | MY Account",
    },
  },
  {
    path: "/liability",
    name: "liability",
    component: Liability,
    meta: {
      title: "VxExch | MY Liability",
    },
  },
  {
    path: "/scorecard",
    name: "scorecard",
    component: ScoreCard,
    meta: {
      title: "VxExch | Score Card",
    },
  },
  {
    path: "/comingsoon",
    name: "comingsoon",
    component: comingsoon,
    meta: {
      title: "VxExch | Coming Soon!",
    },
  },
  {
    path: "/comingsoon-favorites",
    name: "comingsoon-favorites",
    component: comingsoon,
    meta: {
      title: "VxExch | Coming Soon!",
    },
  },
  {
    path: "/comingsoon-in-play",
    name: "comingsoon-in-play",
    component: comingsoon,
    meta: {
      title: "VxExch | Coming Soon!",
    },
  },
  {
    path: "/comingsoon-cricket",
    name: "comingsoon-cricket",
    component: comingsoon,
    meta: {
      title: "VxExch | Coming Soon!",
    },
  },
  {
    path: "/comingsoon-soccer",
    name: "comingsoon-soccer",
    component: comingsoon,
    meta: {
      title: "VxExch | Coming Soon!",
    },
  },
  {
    path: "/comingsoon-tennis",
    name: "comingsoon-tennis",
    component: comingsoon,
    meta: {
      title: "VxExch | Coming Soon!",
    },
  },
  {
    path: "/comingsoon-horse-racing",
    name: "comingsoon-horse-racing",
    component: comingsoon,
    meta: {
      title: "VxExch | Coming Soon!",
    },
  },
  {
    path: "/comingsoon-indian-racing",
    name: "comingsoon-indian-racing",
    component: comingsoon,
    meta: {
      title: "VxExch | Coming Soon!",
    },
  },
  {
    path: "/comingsoon-grey-hound",
    name: "comingsoon-grey-hound",
    component: comingsoon,
    meta: {
      title: "VxExch | Coming Soon!",
    },
  },
  {
    path: "/comingsoon-election",
    name: "comingsoon-election",
    component: comingsoon,
    meta: {
      title: "VxExch | Coming Soon!",
    },
  },
  {
    path: "/comingsoon-game",
    name: "comingsoon-game",
    component: comingsoon,
    meta: {
      title: "VxExch | Coming Soon!",
    },
  },
  {
    path: "/cricket",
    name: "cricket.all",
    component: Cricket,
    meta: {
      title: "VxExch | Cricket",
    },
    props: true,
  },
  {
    path: "/soccer",
    name: "soccer.all",
    component: Soccer,
    meta: {
      title: "VxExch | Soccer",
    },
    props: true,
  },
  {
    path: "/tennis",
    name: "tennis.all",
    component: Tennis,
    meta: {
      title: "VxExch | Tennis",
    },
    props: true,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});
router.beforeEach(VueRouteMiddleware({ AuthMiddleware }));
router.beforeEach((to, from, next) => {
  const publicPages = ["/login"];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem("user");
  if (authRequired && !loggedIn) {
    console.log("here");
    next("/login");
  } else {
    next();
  }
});

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }
  Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map(
    (el) => el.parentNode.removeChild(el)
  );
  if (!nearestWithMeta) return next();
  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });
      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    .forEach((tag) => document.head.appendChild(tag));

  next();
});

export default router;
