<template>
	<div class="sidebar-wrapper text-white">
		<div class="table-responsive mb-4">  
		<label class="text-dark mb-2"> <strong>Unmatched Bets</strong></label>
			<table class="table side-nav-bets-table table-stripped ">
			<thead>
			<tr>
				<th>Runner Name</th>
				<th>Bet Price</th>
				<th>Bet Size</th>
				<th>Event Name</th>
			</tr>
			</thead>
			<tbody>
				<tr v-for="(bat, index) in userUnmatchedEventBats"
				v-bind:index="index"
				v-bind:key="index"
				:style="{
				backgroundColor: getBackgroundColor(
					bat.selected_option
				),
				}">
				<td>
				<span
					style="cursor: pointer"
					@click="cancelUnMatchedBets(bat.id)"
					><i class="fa fa-trash"></i
				></span>
				{{ bat?.stack_type + ":" + bat?.team }}
				</td>
				<td>{{ bat?.bhav }}</td>
				<td>{{ bat?.amount }}</td>                 
				<td>{{ bat?.match_name }}</td>                 
				</tr>
			</tbody>
			</table>
		</div>

		<div class="table-responsive">  
		<label class="text-dark mb-2"> <strong>Matched Bets</strong></label>
			<table class="table table-stripped matched_bets">
			<thead>
			<tr>
				<th>Runner Name</th>
				<th>Bet Price</th>
				<th>Bet Size</th>
				<th>Event Name</th>
			</tr>
			</thead>
			<tbody>
			<tr
				v-for="(bat, index) in userEventBats"
				v-bind:index="index"
				v-bind:key="index"
				:style="{
				backgroundColor: getBackgroundColor(
					bat.selected_option
				),
				}"
			>
				<td>{{ bat?.stack_type + ":" + bat?.team }}</td>
				<td class="text-right">{{ bat?.bhav }}</td>
				<td  class="text-right">{{ bat?.amount }}</td>
				<td  class="text-right">{{ bat?.match_name }}</td>
			</tr>
			</tbody>
			</table>
		</div>
	</div>
</template>

<script>

import SessionService from "@/services/SessionService";
import DataService from "@/services/DataService.js";
// import $ from 'jquery'
export default {
  name: 'HeaderAll',
  data() {
    return {
      publicPath: process.env.BASE_URL,
      auth: {},
      userEventBats : [],
      eventTotalAmount : 0,
      userUnmatchedEventBats : [],
      eventUnmatchedTotalAmount : '',
      macthedEventAverage : '',
      unmacthedEventAverage : '',
    }
  },
  created: function () {
    this.auth = SessionService.getUser();
    this.getEventBatList()
  },
  methods: {
    cancelUnMatchedBets(id) {
      const data = {
        id: id,
      };
      DataService.cancelUnmatchedBets(data)
        .then((response) => {
          if (response.status == 200) {
            const data = response.data;
            if (data.status) {
              this.getEventBatList();
              this.$emit('changeKeyHeader');
              this.$toast.success(data.message);
            }
          }
        })
        .catch((error) => {
          console.log("error", error);
          this.$toast.error("Please Try Again.");
        });
    },
    getBackgroundColor(selectedOption) {
      let color = "";
      if (selectedOption === "Back") {
        color = "#bcdef3";
      } else if (selectedOption === "Lay") {
        color = "#f5dce2";
      }
      return color;
    },
    getEventBatList() {
      
      DataService.getEventBetsList({})
        .then((response) => {
          this.eventBetListRefreshKey += 1;
          const data = response.data.data;
          const total = response.data.total;
          const average = response.data.average;
          if (data.matched) {
            this.userEventBats = data.matched;
          }
          if (total.matched) {
            this.eventTotalAmount = total.matched;
          } else {
            this.eventTotalAmount = "";
          }
          if (data.unmatched) {
            this.userUnmatchedEventBats = data.unmatched;
          }
          if (total.unmatched) {
            this.eventUnmatchedTotalAmount = total.unmatched;
          } else {
            this.eventUnmatchedTotalAmount = "";
          }
          if (average) {
            if (average.matched) {
              this.macthedEventAverage = average.matched;
            } else {
              this.macthedEventAverage = "";
            }
            if (average.unmatched) {
              this.unmacthedEventAverage = average.unmatched;
            } else {
              this.unmacthedEventAverage = "";
            }
          } else {
            this.macthedEventAverage = "";
            this.unmacthedEventAverage = "";
          }
        })
        .catch((e) => {
          console.log("error", e);
          this.isLoading = false;
        });
    },

  },
}
</script>