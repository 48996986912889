<template>
	<HeaderComponent :key="headerRefreshKey" />
	<div class="p-0">
		<div class="main-con d-flex">
			<SideBar />
			<SideBet @changeKeyHeader="updateHeader()"/>
			<div class="tab-content w-100 overflow-auto" id="pills-tabContent">
				<div class="tab-pane fade show active w-100" id="pills-exchange" role="tabpanel" aria-labelledby="pills-exchange-tab" tabindex="0" >
					<main class="main-all-sec-con d-flex flex-column justify-content-start">
						<div class="tab-2-content-sec-1">
							<div class="container p-0">
								<div class="tab-content" id="pills-tabContent">
									<div class="tab-pane fade show active" id="pills-in-play" role="tabpanel" aria-labelledby="pills-in-play-tab" tabindex="0" >
										<div class="tab-2-content-sec-1-inner-con">
											<div class="tab-2-content-sec-1-head-sec d-flex align-items-center justify-content-between flex-nowrap" >
												<h3 class="tab-2-content-sec-1-head">Prorile</h3>
											</div>
											<form @submit.prevent="updateUserProfile" ref="form" id="user_profile_update" enctype="multipart/form-data">
											
												<div class="row  m-4 ">
													<!-- <p class="h1 text-center ">Coming Soon! </p> -->
													<div class="form-group  col-md-3">
														<label for="userName">UserName</label>
														<input readonly type="text" id="userName" class="form-control" v-model="auth.name">
														<span class="error" style="color:red;">{{ error.name }}</span>
													</div>
													<div class="form-group  col-md-3">
														<label for="mobile_number">Mobile No</label>
														<input type="text" id="mobile_number" class="form-control" v-model="auth.mobile_no">
														<span class="error" style="color:red;">{{ error.mobile_no }}</span>
													</div>
													<div class="form-group  col-md-3">
														<label for="city">City</label>
														<input type="text" id="city" class="form-control" v-model="auth.city">
														<span class="error" style="color:red;">{{ error.city }}</span>
													</div>
													<div class="form-group  col-md-3">
														<label for="country">Country</label>
														<input type="text" id="country" class="form-control" v-model="auth.country">
														<span class="error" style="color:red;">{{ error.country }}</span>
													</div>
													<div class="form-group  col-md-3">
														<label for="password">Password</label>
														<input type="password" id="password" class="form-control" v-model="auth.password">
														<span class="error" style="color:red;">{{ error.password }}</span>
													</div>
													<div class="form-group col-md-3 mt-4">
														<button id="filterByDate" class="btn btn-primary btn-filter" type="submit" >Submit</button>
													</div>
												</div>
											</form>
										</div>
									</div>
								</div>
							</div>
						</div>
					</main>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import SessionService from "@/services/SessionService";
import HeaderComponent from "@/components/Header.vue";
import SideBar from "@/components/SideBar.vue";
import DataService from "@/services/DataService.js";
import SideBet from '@/components/SideBet.vue'
// import $ from 'jquery'
export default {
	name: "homeAll",
	components: { HeaderComponent, SideBar , SideBet },
	data() {
		return {
			publicPath: process.env.BASE_URL,
			auth: {},
			error: {},
			headerRefreshKey : 0,
		};
	},
	created: function () {
		this.auth = SessionService.getUser();
	},
	methods: {
		updateHeader(){
			this.refreshHeaderKey += 1;
		},
		updateUserProfile(){
			this.error = []
			const data = {
				name : this.auth.name,
				mobile_no : this.auth.mobile_no,
				city : this.auth.city,
				country : this.auth.country,
				password : this.auth.password,
			}
			DataService.updateUserProfile(data).then( response => {
				// console.log('response' , response)
				if (response.status == 200) {
					const data = response.data
					if(data.status){
						delete this.auth.password
						SessionService.setUser(this.auth);
						this.headerRefreshKey += 1
						this.$toast.success( data.message );
					}
				}
			}).catch( (e) => {
				if (e.response.status == 403) {
					const data = e.response.data
					this.error = []
					if (data.error) {
						if(data.error.name){
							this.error['name'] = data.error.name[0]
						}
					}
				}
			})
		}
	},
};
</script>
