import { createApp } from "vue";

import router from "./router";
import App from "./App.vue";
import axios from "axios";
import VueAxios from "vue-axios";
// import { store } from './store';

import "bootstrap";
// import Select2 from 'vue3-select2-component';

window.$ = window.jQuery = require("jquery");

// import socketio from 'socket.io-client';
// import VueSocketIO from 'vue-socket.io';
// import SessionService from '@/services/SessionService';

import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import "@mdi/font/css/materialdesignicons.css";

const vuetify = createVuetify({
  components,
  directives,
});

// import VueTippy from 'vue-tippy';
// import 'tippy.js/dist/tippy.css'

// const auth = SessionService.getUser();
// var connectionOptions = {
//   'auto connect': true,
//   reconnect: true,
//   'sync disconnect on unload': true,
//   'try multiple transports': true,
//   transports: ['websocket'],
//   upgrade: false,
//   query: {
//     userID: auth ? auth.id : '',
//     role: auth ? auth.role : ''
//   }
// };

const app = createApp(App);

// app.use(
//   new VueSocketIO({
//     debug: false,
//     connection: socketio(process.env.VUE_APP_SOCKET_URL, connectionOptions)
//   })
// );

app.use(router);
app.use(vuetify);
// app.use(VueTippy);
// app.use(store);
app.use(VueAxios, axios);

// app.component('Select2Option', Select2);

//for toaster
const myToast = {
  ...toast,
  success(message, options) {
    return toast.success(message, {
      ...options,
      // theme : 'colored',
      position: "top-left",
      autoClose: 1300,
    });
  },
  error(message, options) {
    return toast.error(message, {
      ...options,
      autoClose: 1300,
      position: "top-left",
      // theme : 'colored',
    });
  },
  info(message, options) {
    return toast.info(message, {
      ...options,
      autoClose: 1300,
      position: "top-left",
      // theme : 'colored',
    });
  },
};

app.config.globalProperties.$toast = myToast;

app.mount("#app");
