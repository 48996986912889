<template>
  <HeaderComponent :key="this.refreshHeaderKey" />

  <!-- Login Modal -->
  <div
    class="modal fade"
    id="loginModal"
    tabindex="-1"
    aria-labelledby="loginModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="loginModalLabel">Login</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form>
            <div class="mb-3">
              <label for="username" class="form-label">Username</label>
              <input
                type="text"
                class="form-control"
                id="username"
                placeholder="Enter your username"
              />
            </div>
            <div class="mb-3">
              <label for="password" class="form-label">Password</label>
              <input
                type="password"
                class="form-control"
                id="password"
                placeholder="Enter your password"
              />
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button>
          <button type="button" class="btn btn-primary">Login</button>
        </div>
      </div>
    </div>
  </div>
  <div class="p-0">
    <div class="main-con d-flex">
      <SideBar />
      <SideBet @changeKeyHeader="updateHeader()" />
      <div class="tab-content w-100 overflow-auto" id="pills-tabContent">
        <div
          class="tab-pane fade show active w-100"
          id="pills-exchange"
          role="tabpanel"
          aria-labelledby="pills-exchange-tab"
          tabindex="0"
        >
          <main class="main-all-sec-con d-flex flex-column justify-content-start">
            <div class="tab-2-content-sec-1">
              <div class="container p-0">
                <div class="tab-content" id="pills-tabContent">
                  <div
                    class="tab-pane fade show active"
                    id="pills-in-play"
                    role="tabpanel"
                    aria-labelledby="pills-in-play-tab"
                    tabindex="0"
                  >
                    <div class="tab-2-content-sec-1-inner-con">
                      <div
                        class="tab-2-content-sec-1-head-sec d-flex align-items-center justify-content-between flex-nowrap"
                      >
                        <h3 class="tab-2-content-sec-1-head">Bets List</h3>
                      </div>
                      <div class="row m-4">
                        <div class="form-group col-md-3">
                          <label for="outcomeFilter">Filter by Outcome:</label>
                          <select class="form-control" id="outcomeFilter" v-model="filterOutcome">
                            <option value="all">All</option>
                            <option value="result_pending">Result Pending</option>
                            <option value="won">Win</option>
                            <option value="lost">Lose</option>
                          </select>
                        </div>
                        <div class="form-group col-md-3">
                          <label for="statusFilter">Filter by Status:</label>
                          <select class="form-control" id="statusFilter" v-model="filterStatus">
                            <option value="all">All</option>
                            <option value="upcoming">Upcoming</option>
                            <option value="running">Running</option>
                            <option value="finish">Finish</option>
                          </select>
                        </div>
                        <div class="form-group col-md-3">
                          <label for="startDate">Start Date:</label>
                          <!-- <input type="date" id="startDate" class="form-control"  v-model="filterStartDate" :config="dateConfig"/> -->
                          <flat-pickr
                            v-model="filterStartDate"
                            :config="dateConfig"
                            class="form-control"
                          ></flat-pickr>
                        </div>
                        <div class="form-group col-md-3">
                          <label for="endDate">End Date:</label>
                          <flat-pickr
                            v-model="filterEndDate"
                            :config="dateConfig"
                            class="form-control"
                          ></flat-pickr>
                        </div>
                        <div class="form-group col-md-3 align-self-end">
                          <button id="filterByDate" class="btn btn-primary btn-filter" @click="filterBetTable">Apply Filter</button>
                        </div>
                      </div>
                      <div class="total-amt">
                        Total Bet Amount: {{ betTotalAmount }}
                      </div>
                      <div class="bets-table">
                        <table ref="dataTable" class="display" style="width:100%"></table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
        <div class="tab-pane fade" id="pills-ezugi" role="tabpanel" aria-labelledby="pills-ezugi-tab" tabindex="0">
          <h1 class="text-center m-5 p-0" style="font-size: 30px">Hello</h1>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SessionService from "@/services/SessionService";
import DataService from "@/services/DataService.js";
import $ from 'jquery';
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import "datatables.net-buttons-dt/css/buttons.dataTables.css";
import 'datatables.net-dt/css/dataTables.dataTables.css';
import HeaderComponent from "@/components/Header.vue";
import SideBar from '@/components/SideBar.vue';
import SideBet from '@/components/SideBet.vue';
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

export default {
  name: 'homeAll',
  components: {
    HeaderComponent,
    SideBar,
    SideBet,
    flatPickr
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      auth: {},
      events: [],
      filterOutcome: 'all',
      filterStartDate: '',
      filterEndDate: '',
      betDataTable: '',
      betTotalAmount: '',
      filterStatus: 'all',
      refreshHeaderKey: 0,
      dateConfig: {
        dateFormat: "Y-m-d", // Date format for the input value
        altInput: true, // Enables alternative input
        altFormat: "d/m/Y", // Date format for display
      },
      search: '',
      columnsDropdown: false,
      columnSearch: "",
      headers: [
        { key: "no", title: "No", visible: true },
        { key: "event_id", title: "Event Id", visible: true },
        { key: "user_ip", title: "User Ip", visible: true },
        { key: "match_name", title: "Match Name", visible: true },
        { key: "runner_name", title: "Runner Name", visible: true },
        { key: "price", title: "Price", visible: true },
        { key: "amount", title: "Amount", visible: true },
        { key: "outcome", title: "Outcome", visible: true },
        { key: "side", title: "Side", visible: true },
        { key: "date", title: "Placed Date", visible: true },
      ],
      desserts: [],
      filters: [],
      columnNames: [],
      operations: [
        "contains",
        "equals",
        "starts with",
        "ends with",
        "is empty",
        "is not empty",
        "is any of",
      ],
    };
  },
  created: function () {
    this.auth = SessionService.getUser();
    this.columnNames = this.headers.map(header => header.title);
    this.addFilter();
    $(document).ready(() => {
      this.getUserTransaction();
    });
  },
  computed: {
    filteredHeaders() {
      return this.headers.filter(header => header.visible);
    },
  },
  methods: {
    updateHeader() {
      this.refreshHeaderKey += 1;
    },
    showColumnsDropdown() {
      this.columnsDropdown = !this.columnsDropdown;
    },
    hideAllColumns() {
      this.headers.forEach((header) => (header.visible = false));
    },
    showAllColumns() {
      this.headers.forEach((header) => (header.visible = true));
    },
    addFilter() {
      this.filters.push({ column: null, operation: null, value: "" });
    },
    deleteFilter(index) {
      this.filters.splice(index, 1);
    },
    logOut() {
      DataService.logout().then(response => {
        if (response.status == 200) {
          localStorage.removeItem('user');
          this.$router.push({ name: 'login' });
        }
      })
        .catch(e => {
          console.log('error', e);
          this.isLoading = false;
        });
    },
    getUserTransaction() {
      try {
        this.betDataTable = $(this.$refs.dataTable).DataTable({
          processing: true,
          serverSide: true,
          destroy: true,
          pageLength: 10,
          searching: false,
          autoWidth: false,
          aaSorting: [],
          columnDefs: [{
            orderable: false,
            targets: [1, 2]
          },
          {
            targets: [5, 6],
            className: "text-right",
          }
          ],
          ajax: {
            url: DataService.getUserBetsLink(),
            beforeSend: function (request) {
              request.setRequestHeader('Authorization', DataService.getAuthToken());
              return true;
            }, data: (d) => {
              return d;
            }
          },
          columns: [
            { title: 'No', data: 'index' },
            {
              title: 'Event Id',
              data: 'event_id',
              render: function (data, type, row) {
                data = '<a href="event/' + row['event_type'] + '/' + data + '">' + data + '</a>';
                return data;
              }
            },
            { title: 'User Ip', data: 'user_ip' },
            { title: 'Match Name', data: 'match_name' },
            { title: 'Runner Name', data: 'team' },
            { title: 'Price', data: 'size' },
            { title: 'Amount', data: 'amount' },
            { title: 'Outcome', data: 'outcome' },
            { title: 'Side', data: 'selected_option' },
            { title: 'Placed Date', data: 'created_at' },
          ],
          initComplete: () => {
            if (this.betDataTable.ajax.json()) {
              const data = this.betDataTable.ajax.json();
              if (data.total || data.total == 0) {
                this.betTotalAmount = data.total;
              }
              if (data.startDate) {
                this.filterStartDate = data.startDate;
              }
              if (data.endDate) {
                this.filterEndDate = data.endDate;
              }
            }
          }
        });
      } catch (error) {
        console.log('error', error);
      }
    },
    filterBetTable() {
      this.betDataTable.settings()[0].ajax.data = (d) => {
        d.outcome = this.filterOutcome;
        d.startDate = this.filterStartDate;
        d.endDate = this.filterEndDate;
        d.status = this.filterStatus;
      };
      this.betDataTable.ajax.reload((data) => {
        this.betTotalAmount = data.total;
      });
    },
  },
}
</script>
